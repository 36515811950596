import React from 'react';
import { AppleHealthType, GoogleFitType, DeviceData, ObservationConnect } from 'services/constants';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { isIphone, isAndroid, isIOS, isMobile } from 'mobile/helpers';
import { mobileAppRoot } from 'app/config';
import { ButtonWithOneClick } from './DeviceButtonHelper';

export default function ConnectDeviceList(props){
  const sources = props.sources;
  const goalCategoryId = props.goalCategoryId;
  function connectOthers(x) {
    let redirectUri = window.location.href.replace(routePaths.connectDevice, routePaths.healthDevices);

    if (isMobile) {
      redirectUri = `${mobileAppRoot}${DeviceData}?close=true`;
    }
    if(props.isFromObservationConnectPage){
      var params = {
        observationType : props.observationType,
        source: x.type,
        goalCategoryId: goalCategoryId
      };
      const searchParams = new URLSearchParams(params);
      redirectUri = window.location.href.replace(routePaths.connectDevice, routePaths.observationConnect+`?${searchParams && searchParams.toString().replace(/&/g,'%26')}`);
      if (isMobile) {
        redirectUri = `${mobileAppRoot}${ObservationConnect}?${searchParams && searchParams.toString().replace(/&/g,'%26')}`;

        if (x.type === AppleHealthType || x.type === GoogleFitType) {
          redirectUri = `${ObservationConnect}&${searchParams && searchParams.toString()}`;
        }
      }
    }
    return (
      // eslint-disable-next-line react/jsx-no-bind
      <div className='device'>
        <div className="device-title">
          <img className={x.type + '-logo-align'} src={(((isIOS && x.type !== AppleHealthType)|| (!isAndroid && x.type === GoogleFitType)) ? 'http:' : '') + x.logo_url} />
          <div>{x.display_name}</div>
        </div>
        { (!isIphone && x.type === AppleHealthType) ? <div className="apple-health-title"><strong>{local.devices.healthKitButtonExplanationFirst}</strong> {local.devices.healthKitButtonExplanation}</div>  :
          (!isAndroid && x.type === GoogleFitType) ?
            <div className="apple-health-title"><strong>{local.devices.healthKitButtonExplanationFirst}</strong> {local.devices.googleFitButtonExplanation}
            </div> :
            <ButtonWithOneClick
              id={x.type} className="small-btn btn btn-primary"  onClick={(e) => {
                props.openRoute(x.type, x.connect_url + '&redirect_uri=' + redirectUri, x.provider); e.preventDefault();
              }}>{local.devices.connectLink}
            </ButtonWithOneClick>}

      </div>
    );
  }
  return (
    sources.map(x =>
    {
      if((isAndroid && props.googleFitConnected == false && x.type === GoogleFitType) || (isAndroid && x.type !== GoogleFitType) || !isAndroid)
      {
        return (<li key={x.type}> {connectOthers(x)} </li>);
      }
    }
    )
  );
}
