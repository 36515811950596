import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import { isIphone, openMobileRoute, isIOS, isAndroid , isGooglefitEnabled, disconnectGooglefit} from 'mobile/helpers';
import local from 'services/localization/local';
import { NoDataPlaceholder, Loading, NavIcon } from 'shared';
import { loadConnectedDevices, disconnectDevice, loadSyncedData, connectTerraDevice, loadTerraDevices, disconnectTerraDevice } from 'devices/devices-actions';
import { bindActionCreators } from 'redux';
import { getValidicHealthKitDisconnectUrl, googleFitName,getDevices, getValidicUserId, getValidicGoogleFitMobileUrl, getSyncedData, isGetDevicesLoaded, isGetDevicesLoading  } from 'devices/devices-selectors';
import DisconnectConfirmModal from 'devices/overview/DisconnectConfirmModal';
import { getObservationIcon } from 'devices/device-helper';
import { ObservationType } from 'services/enums';
import { Activities } from 'shared/fullColorIcons';
import withNavigate from 'app/withNavigation';
import { AppleHealthType, GoogleFitType, FromGoogleFitConnect, googleFitLogo, terraProvider } from 'services/constants';
import ConnectedDevicesCard from './ConnectedDevicesCard';
const appleHealthLogo = 'https://static.myhc.app/images/apple-health.png';


class ConnectedDevices extends Component {
  constructor(props) {
    super(props);
    this.getConnectedDevices = this.getConnectedDevices.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.checkDeviceDisconnected = this.checkDeviceDisconnected.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.disconnectGoogleFit = this.disconnectGoogleFit.bind(this);
    this.CheckGoogleFitConnected = this.CheckGoogleFitConnected.bind(this);
    this.renderSyncedData = this.renderSyncedData.bind(this);
    this.timeoutId = '';
    this.state = {
      showModal: false,
      deviceType: '',
      logoUrl: '',
      googleFitConnected: false,
      disconnectingDeviceType : ''
    };
  }

  componentDidMount() {
    this.getConnectedDevices();
  }
  componentWillUnmount() {
    clearInterval(this.timeoutId);
  }

  async getConnectedDevices() {
    const { actions, profileId } = this.props;
    actions.loadConnectedDevices(profileId);
    actions.loadSyncedData(profileId);
    actions.loadTerraDevices(profileId);

    // Checking Google Fit Connected
    if(isAndroid)
    {
      const fromGoogleFitConnect = new URLSearchParams(location.search).get(FromGoogleFitConnect);
      const checkCount = fromGoogleFitConnect ? 200 : 1; // 200 * 3 = 600 seconds waiting for connection
      this.CheckGoogleFitConnected(checkCount);
    }
  }

  CheckGoogleFitConnected(checkCount){

    const { actions, profileId, validicUserId } = this.props;

    this.timeoutId = setTimeout(async () => {

      if(isAndroid)
      {
        const  value  = await isGooglefitEnabled(validicUserId);
        this.setState({googleFitConnected:value});

        if(value == true )
        {
          clearTimeout(this.timeoutId);
          return;
        }
        else
        {
          checkCount = checkCount - 1;
          if(checkCount > 0)
          {
            actions.loadConnectedDevices(profileId);
            this.CheckGoogleFitConnected(checkCount);
          }
        }
      }

    }, 2000);
    return () => clearTimeout(this.timeoutId);

  }

  triggerModal(deviceType, logoUrl, provider, providerUserId) {
    let logo = ((isIOS && deviceType !== AppleHealthType) ? 'http:' : '') + logoUrl;
    if (deviceType === AppleHealthType) {
      logo = appleHealthLogo;
    }
    this.setState(prevState => ({ showModal: !(prevState.showModal), deviceType: deviceType, logoUrl: logo, provider: provider, providerUserId: providerUserId}));
  }

  disconnect() {
    const { actions, profileId, showHealthKit } = this.props;
    const { deviceType, provider, providerUserId } = this.state;
    if (deviceType != undefined && deviceType != '') {
      if (showHealthKit && deviceType == AppleHealthType)
      {
        this.disconnectApple();
      }
      else if(isAndroid && deviceType == GoogleFitType)
      {
        this.disconnectGoogleFit();
      }
      else if(provider == terraProvider)
      {
        actions.disconnectTerraDevice(profileId, providerUserId);
      }
      else
        actions.disconnectDevice(profileId, this.state.deviceType);

      this.setState(prevState => ({ showModal: !(prevState.showModal), deviceType: '', logoUrl: '', disconnectingDeviceType:deviceType }));
    }
  }

  disconnectApple() {
    const { healthKitUrl } = this.props;
    openMobileRoute(healthKitUrl);
    this.getConnectedDevices();
    this.checkDeviceDisconnected();
  }

  async disconnectGoogleFit() {
    await disconnectGooglefit();
    setTimeout(async ()=>{
      const { validicUserId } = this.props;

      const  value  = await isGooglefitEnabled(validicUserId);
      this.setState({googleFitConnected:value});
    }, 3000);
  }

  checkDeviceDisconnected(){
    const { devices, showHealthKit} = this.props;
    var appleHealth = undefined;
    if (devices) {
      appleHealth = devices?.find(d => d.type === AppleHealthType && d.connected == true);
    }
    this.timeoutId = setTimeout(() =>{
      if (appleHealth && showHealthKit) {
        this.getConnectedDevices();
        this.checkDeviceDisconnected();
      }
      else{
        return () => clearTimeout(this.timeoutId);
      }
    }, 3000);
    return () => clearTimeout(this.timeoutId);
  }

  renderSyncedData(device) {
    const { syncedData } = this.props;
    if (syncedData && syncedData.data) {
      const deviceData = syncedData.data.find(d => d.device === device);

      if (deviceData) {
        const icons = [];
        if(deviceData.activityTypes)
          icons.push(<div className="device-metric-item" key="activity-icon"><NavIcon Icon={Activities} /></div>);

        if (deviceData.observationTypes) {
          deviceData.observationTypes[deviceData.observationTypes.indexOf(ObservationType.Diastolic)] = ObservationType.Systolic;
          const distinctObservationTypes = [...new Set(deviceData.observationTypes)];
          icons.push(distinctObservationTypes.map(observationType => <div className="device-metric-item" key={observationType}><NavIcon Icon={getObservationIcon(observationType)} /></div>));
        }
        return icons;
      }
    }
  }

  renderList(devices) {
    const { googleFitConnectedInValidic } = this.props;
    return(
      <ConnectedDevicesCard disconnectingDeviceType={this.state.disconnectingDeviceType} devices={devices} googleFitConnectedInValidic={googleFitConnectedInValidic} googleFitConnected={this.state.googleFitConnected} triggerModal={this.triggerModal} renderSyncedData={this.renderSyncedData}></ConnectedDevicesCard>
    );
  }

  render() {
    const { devices, deviceLoading, isloaded, googleFitConnectedInValidic } = this.props;

    let filteredDevices = devices;

    if(isAndroid )
    {
      if(!this.state.googleFitConnected)
      {
        filteredDevices = filteredDevices?.filter(x => x.type !== GoogleFitType);
      }
      else if( this.state.googleFitConnected && googleFitConnectedInValidic == false && !filteredDevices?.find(d => d.type === GoogleFitType))
      {
        filteredDevices.push({
          type:GoogleFitType,
          logo_url : googleFitLogo,
          display_name : googleFitName
        });
      }
    }

    const loadingTile = (
      <React.Fragment>
        {deviceLoading ? <Loading /> : null}
        {!deviceLoading && isloaded && filteredDevices.length > 0 ?
          <div className="connected-device-tile">
            <div>
              <h5 className="connected-devices-title"> {local.devices.connectedDevicesTitle}</h5>
              <ul className="connected-devices">{this.renderList(filteredDevices)} </ul>
            </div>
          </div> : null}
      </React.Fragment>
    );

    return (
      <div className="devices-connected">
        {devices && devices !== undefined && devices.length > 0 ? (
          <div>
            {(loadingTile)}
            <div>
              {this.state.showModal ?
                <DisconnectConfirmModal
                  modalTitle={local.devices.disconnectConfirmationTitle}
                  modalPromptText={local.devices.disconnectExplanation}
                  logo={this.state.logoUrl}
                  // eslint-disable-next-line react/jsx-no-bind
                  handleYes={() => this.disconnect()} handleNo={() => this.triggerModal('')}
                  isOpen />
                : null}
            </div>
          </div>
        ) : <NoDataPlaceholder>{ }</NoDataPlaceholder>}
      </div>
    );
  }
}

ConnectedDevices.propTypes = {
  actions: PropTypes.shape({
    loadConnectedDevices: PropTypes.func.isRequired,
    disconnectDevice: PropTypes.func.isRequired,
    loadSyncedData: PropTypes.func.isRequired,
    connectTerraDevice: PropTypes.func.isRequired,
    loadTerraDevices: PropTypes.func.isRequired
  }).isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape()),
  profileId: PropTypes.string.isRequired,
  showHealthKit: PropTypes.bool.isRequired,
  healthKitUrl: PropTypes.string,
  deviceLoading: PropTypes.bool.isRequired,
  isloaded: PropTypes.bool,
  syncedData: PropTypes.shape()
};

function  mapStateToProps(state, props) {
  const healthkit = getValidicHealthKitDisconnectUrl(state, props.profileId);
  const googleFitUrl = getValidicGoogleFitMobileUrl(state, props.profileId);
  const validicUserId = getValidicUserId(state, props.profileId);
  const devices = getDevices(state, props.profileId, healthkit, googleFitUrl);
  // eslint-disable-next-line import/namespace

  let googleFitConnectedInValidic = devices && devices.filter(x => x.type === GoogleFitType);


  return {
    devices: devices ,
    deviceLoading: isGetDevicesLoading(state, props.profileId),
    isloaded: isGetDevicesLoaded(state, props.profileId),
    showHealthKit: isIphone,
    healthKitUrl: healthkit,
    googleFitUrl : googleFitUrl,
    syncedData: getSyncedData(state, props.profileId),
    validicUserId : validicUserId,
    googleFitConnectedInValidic : googleFitConnectedInValidic && googleFitConnectedInValidic.length > 0
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { loadConnectedDevices, disconnectDevice, loadSyncedData, connectTerraDevice, loadTerraDevices, disconnectTerraDevice};
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withNavigate(withContext(connect(mapStateToProps, mapDispatchToProps)(ConnectedDevices)));
