/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import * as selectors from 'devices/devices-selectors';
import {getObservationConnections} from 'profile-observation-connections/selectors';
import { isIphone, isAndroid, isMobile, openRouteInApp,  isGooglefitEnabled, openMobileRoute } from 'mobile/helpers';
import local from 'services/localization/local';
import { DataLoadingStatus, NoDataPlaceholder, Card, BackAction } from 'shared';
import { loadDevices, loadConnectedDevices, getTerraConnectionUrl, loadTerraDevices, loadActiveDevices } from 'devices/devices-actions';
import { routePaths } from 'services/routing';
import { bindActionCreators } from 'redux';
import ProfilePage from 'profiles/page/ProfilePage';
import withNavigate from 'app/withNavigation';
import { getValidicHealthKitConnectUrl } from 'devices/devices-selectors';
import { mobileAppRoot } from 'app/config';
import ConnectDeviceList from './ConnectDeviceList';
import { AppleHealthType, AppleHealthParam, DeviceData, GoogleFitType, FromGoogleFitConnect, terraProvider, GoalCategoryId } from 'services/constants';
import { getDevices, getFilteredDevices, handleBack } from 'devices/device-helper';
import './devices.scss';
import { baseUrl } from 'services/url-service';
import ObservationConnectedDevices from 'profile-observation-connections/ObservationConnectedDevices';
import DeviceSurvey from './DeviceSurvey';

class ConnectDevices extends Component {
  constructor(props) {
    super(props);
    this.terraConnect = this.terraConnect.bind(this);
    this.openRoute = this.openRoute.bind(this);
    this.checkDeviceConnected = this.checkDeviceConnected.bind(this);
    this.CheckGoogleFitConnected = this.CheckGoogleFitConnected.bind(this);
    this.state = {
      googleFitConnected: false
      // isDisabled:false
    };

  }

  componentDidMount() {
    const { showHealthKit, actions, profileId } = this.props;
    getDevices(this.props);
    actions.loadActiveDevices(profileId);
    if (showHealthKit || isAndroid) {
      this.getConnectedDevices();
    }
  }


  componentWillUnmount() {
    clearInterval(this.timeoutId);
  }


  openRoute(type, url, provider) {
    const { connectedDevices, navigate, healthKitUrl, googleFitUrl } = this.props;
    const connectDeviceUrl =  url && decodeURIComponent(url).substring(url.indexOf('?') + 1);
    const urlParams = new URLSearchParams(connectDeviceUrl);
    const observationType = urlParams.get('observationType');
    const source = urlParams.get('source');
    let goalCategoryId = urlParams.get(GoalCategoryId);

    goalCategoryId = (goalCategoryId === undefined || goalCategoryId === null || goalCategoryId === 'null') ? '' : goalCategoryId;

    let isFromObservationConnectPage = false;
    if(url.toString().includes('observationType')){
      isFromObservationConnectPage = true;
    }else{
      isFromObservationConnectPage = false;
    }

    if (type === AppleHealthType) {
      openMobileRoute(healthKitUrl);
      let navigateBack = false;
      setTimeout(() => { navigateBack = true; }, 90000);

      if (connectedDevices && connectedDevices.find(d => d.type === AppleHealthType && d.connected === true)) {
        if(isFromObservationConnectPage){
          navigate({ to: `${routePaths.observationConnect}?observationType=${observationType}&source=${source}&goalCategoryId=${goalCategoryId}` });
        }else{
          navigate({ to: `${routePaths.healthDevices}?${AppleHealthParam}=true` });
        }
        return;
      }
      //  this.getConnectedDevices();
      this.checkDeviceConnected(navigateBack,isFromObservationConnectPage,observationType,source,goalCategoryId);
    }
    else if (type === GoogleFitType) {
      openMobileRoute(googleFitUrl);
      this.getConnectedDevices();
      if(isFromObservationConnectPage){
        this.CheckGoogleFitConnected(200,observationType,source, goalCategoryId);
      }else{
        setTimeout(() => { navigate({ to: `${routePaths.healthDevices}?${FromGoogleFitConnect}=true` }); }, 2000);
      }

    }
    else if (provider === terraProvider) {
      this.terraConnect(type, isFromObservationConnectPage, observationType, goalCategoryId ?? '');
    }
    else {
      if (isMobile) {
        openRouteInApp(url);
      }
      else {
        window.location.href = url;
      }
    }
  }

  CheckGoogleFitConnected(checkCount,observationType,source, goalCategoryId){
    const { actions, profileId, validicUserId,navigate } = this.props;

    this.timeoutId = setTimeout(async () => {

      if(isAndroid)
      {
        const  value  = true;


        if(value == true )
        {
          clearTimeout(this.timeoutId);
          navigate({ to: `${routePaths.observationConnect}?observationType=${observationType}&source=${source}&goalCategoryId=${goalCategoryId}` });
          return;
        }
        else
        {
          checkCount = checkCount - 1;
          if(checkCount > 0)
          {
            actions.loadConnectedDevices(profileId);
            this.CheckGoogleFitConnected(checkCount,observationType,source, goalCategoryId);
          }
        }
      }

    }, 2000);
    return () => clearTimeout(this.timeoutId);

  }
  checkDeviceConnected(navigateBack,isFromObservationConnectPage,observationType,source,goalCategoryId){
    const {  navigate } = this.props;
    this.timeoutId = setTimeout(() =>{
      this.getConnectedDevices().then(()=>{
        const _connectedDevices = this.props.connectedDevices;
        if (navigateBack || _connectedDevices) {
          const appleHealth = _connectedDevices?.find(d => d.type === AppleHealthType && d.connected === true);
          if (navigateBack || appleHealth) {
            clearTimeout(this.timeoutId);
            if(isFromObservationConnectPage){
              navigate({ to: `${routePaths.observationConnect}?observationType=${observationType}&source=${source}&goalCategoryId=${goalCategoryId}` });
            }else{
              navigate({ to: `${routePaths.healthDevices}?${AppleHealthParam}=${appleHealth ? true : false}` });
            }
            return;
          }
        }
      });
      this.checkDeviceConnected(navigateBack, isFromObservationConnectPage, observationType, source, goalCategoryId);
    }, 3000);
    return () => clearTimeout(this.timeoutId);
  }

  async getConnectedDevices() {
    const { actions, profileId, validicUserId } = this.props;

    actions.loadConnectedDevices(profileId);

    // Checking Google Fit Connected
    if(isAndroid)
    {
      const value  = await isGooglefitEnabled(validicUserId);
      this.setState({googleFitConnected:true});
    }

  }

  terraConnect(deviceSourceName, isFromObservationConnectPage, observationType, goalCategoryId ) {
    const { actions, profileId } = this.props;

    let params = `fromTerra=true&profile_Id=${profileId}&source=${deviceSourceName}`;
    if(isFromObservationConnectPage)
    {
      params = `${params}&observationType=${observationType}&isProfileConnect=true&goalCategoryId=${goalCategoryId}`;
    }
    else{
      params = `${params}&isProfileConnect=false&observationType=0&goalCategoryId=`;
    }

    let redirectUrl = `${baseUrl}/health/devices?${params}`;

    if (isMobile) {
      redirectUrl = `${mobileAppRoot}${DeviceData}?${params}`;
    }

    actions.getTerraConnectionUrl(profileId, deviceSourceName, redirectUrl);
  }

  renderList(sources) {
    const { goalCategoryId } = this.props;
    const programId = this.props?.location?.state?.backpath?.programId;
    const observationType = this.props?.location?.state?.backpath?.observationType;
    const isFromObservationConnectPage = this.props?.location?.state?.backpath?.fromPath === routePaths.observationConnect;
    return(
      <ConnectDeviceList goalCategoryId={goalCategoryId} sources={sources} programId={programId} observationType={observationType} isFromObservationConnectPage={isFromObservationConnectPage} googleFitConnected={this.state.googleFitConnected} openRoute={this.openRoute}></ConnectDeviceList>
    );
  }

  render() {
    const { devices, deviceLoading, validicUserId, goalCategoryId } = this.props;
    const filteredDevices = getFilteredDevices(this.props, this.state.googleFitConnected);
    const isFromObservationConnectPage = this.props?.location?.state?.backpath?.fromPath === routePaths.observationConnect;
    const programId = this.props?.location?.state?.backpath?.programId;
    const deviceSourceName = this.props?.location?.state?.backpath?.deviceSourceName;
    const observationType = this.props?.location?.state?.backpath?.observationType;
    const title = this.props?.location?.state?.backpath?.observationType ? local.formatString(local.observationConnect.connectTitleText, local.observationsTitle[this.props?.location?.state?.backpath?.observationType]) : local.devices.connectDevices;
    const goalName = this.props?.location?.state?.backpath?.goalName;
    return (
      <ProfilePage
        id='devices'
        centerElement={title} readOnlyProfile titleTabText={local.devices.connectDevices} backAction={<BackAction handleBack={() => handleBack(this.props)} />}>
        <DataLoadingStatus loading={deviceLoading} >
          {isFromObservationConnectPage ? <ObservationConnectedDevices goalCategoryId={goalCategoryId} goalName={goalName} validicUserId={validicUserId} programId={programId} deviceSourceName={deviceSourceName} observationType={observationType}></ObservationConnectedDevices> : null}
          {devices && devices !== undefined && devices.length > 0 && !isFromObservationConnectPage ? (
            <Card className="devices-card">
              <ul className="disconnected-devices">{this.renderList(devices)} </ul>
            </Card>) : null}
          {!devices || devices.length === 0 ? <NoDataPlaceholder>{ }</NoDataPlaceholder> : null}
          {filteredDevices && filteredDevices !== undefined && filteredDevices.length > 0 && isFromObservationConnectPage ? (
            <Card className="devices-card">
              <ul className="disconnected-devices">{this.renderList(filteredDevices)} </ul>
            </Card>
          ) : null}
          {isFromObservationConnectPage ?
            <DeviceSurvey
              programId={this.props?.location?.state?.backpath?.programId}
              observationType={this.props?.location?.state?.backpath?.observationType} />
            : null}
        </DataLoadingStatus>
      </ProfilePage>
    );
  }
}


ConnectDevices.propTypes = {
  actions: PropTypes.shape({
    loadDevices: PropTypes.func.isRequired,
    loadConnectedDevices: PropTypes.func.isRequired,
    getTerraConnectionUrl: PropTypes.func.isRequired,
    loadTerraDevices: PropTypes.func.isRequired
  }).isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape()),
  profileId: PropTypes.string.isRequired,
  showHealthKit: PropTypes.bool.isRequired,
  navigate: PropTypes.func,
  healthKitUrl: PropTypes.string,
  googleFitUrl: PropTypes.string,
  deviceLoading: PropTypes.bool.isRequired,
  connectedDevices: PropTypes.arrayOf(PropTypes.shape())
};

function mapStateToProps(state, props) {
  const healthkit = getValidicHealthKitConnectUrl(state, props.profileId);
  const googleFitUrl = selectors.getValidicGoogleFitMobileUrl(state, props.profileId);
  const validicUserId = selectors.getValidicUserId(state, props.profileId);
  // eslint-disable-next-line no-unused-vars
  return {
    devices: selectors.getDisconnectedDevices(state, props.profileId, healthkit),
    healthKitUrl: healthkit,
    googleFitUrl : googleFitUrl,
    deviceLoading: selectors.isgetDisconnectedDevicesLoading(state, props.profileId),
    showHealthKit: isIphone,
    connectedDevices: selectors.getConnectedDevices(state, props.profileId),
    validicUserId : validicUserId,
    observationConnections: getObservationConnections(state),
    goalCategoryId: props?.location?.state?.backpath?.goalCategoryId
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { loadDevices, loadConnectedDevices, getTerraConnectionUrl, loadTerraDevices, loadActiveDevices };
  return { actions: bindActionCreators(actions, dispatch) };
}
export default withNavigate(withContext(connect(mapStateToProps, mapDispatchToProps)(ConnectDevices)));
