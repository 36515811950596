import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import './verified-change-email.scss';
import { Card, Icon, Page, Button, ModalWrapper } from 'shared';
import { closeRouteInApp, isMobile, openMobileRoute } from 'mobile/helpers';
import { mobileAppRoot } from 'app/config';
import { Application, KeyType, PaymentStatus, RecursiveInterval } from 'services/enums';
import { initiatePayment } from '../../products/products-actions';
import '../../products/coach.scss';
import { Close, OpenApp, Tid, USD } from 'services/constants';
import { Redirect, useLocation } from 'react-router';
import { getSelfProfileId } from 'profiles/list/selectors';
import { getSubscription, SubsriptionType, SubscriptionList } from './Subscription';
import moment from 'moment';

function ChangeEmail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const subscription = getSubscription();
  const listOfSubscription = SubscriptionList();
  const initialSubscription = listOfSubscription.filter(x => x.isMonthly === true);
  const selfProfileId = useSelector(getSelfProfileId);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  const [isMonthly, setIsMonthly] = useState(true);
  const [annually, coachDescription] = ['ANNUALLY', 'Calcium Health Pathways'];
  const content = local.coach;
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const isCloseParamReceived = new URLSearchParams(location.search).get(Close);
    let transactionId = new URLSearchParams(location.search).get(Tid);

    if (transactionId && transactionId.includes('"')) {
      transactionId = transactionId.split('"')[1];
    }

    if (!transactionId && location.hash && location.hash.includes(Tid) && location.hash.includes('=')) {
      transactionId = new URLSearchParams(location.hash.substring(1)).get(Tid);
    }

    if (transactionId !== null && transactionId !== undefined && transactionId.length > 0) {
      setIsPaymentSuccess(true);
    }

    if (isCloseParamReceived !== null && isCloseParamReceived !== undefined) {
      closeRouteInApp();
      openMobileRoute(OpenApp);
    }
  }, []);

  useEffect(() => {
    var filteredList = listOfSubscription.filter(x => x.isMonthly === isMonthly);
    console.log(isMonthly,filteredList);
    setPlans(filteredList);
  },[isMonthly]);

  function doPayment(id) {
    setIsDisableSubmit(true);
    const selectedPlan = listOfSubscription.find(plan => plan.id === id);
    if (selectedPlan) {
      const payment = {
        amount: selectedPlan.offerPrice,
        itemName: selectedPlan.planName,
        currency: USD,
        quantity: 1,
        redirectUri: isMobile ? `${mobileAppRoot}paymentsuccess` : `https://${window.location.host}${routePaths.viewPlan}`,
        description: coachDescription,
        application: Application.MemberApp,
        paymentStatus: PaymentStatus.RequestedToPaymentGateway,
        keyType: KeyType.ProfileId,
        key: selfProfileId,
        isRecursive: true,
        recursiveInterval: RecursiveInterval.Month,
        intervalCount: selectedPlan.intervalCount
      };
      dispatch(initiatePayment(payment));
    }
  }

  // function inviteMember(transactionId) {
  //   dispatch(createInvite(transactionId));
  // }

  function onChange(selectedPlan) {
    setPlans(plans.map(plan => ({ ...plan, isChecked: plan.id === selectedPlan.id })));
  }

  function toggleModal() {
    setShowModal(!showModal);
    navigate({ to: location.state?.from ?? routePaths.root });
  }

  function setTab(isMonthlyPlan){
    //setLoading(true);
    setPlans([]);
    setIsMonthly(isMonthlyPlan);
    //setLoading(false);
  }

  const getDiscountPercentage = (originalPrice, offerPrice) => Math.round(100 * (originalPrice - offerPrice) / originalPrice);

  if (isPaymentSuccess)
    return <Redirect to={routePaths.root} />;

  function renderPlans() {
    return plans.length > 0 && plans.map(plan => (
      <Card key={plan.name} customClass="card-component subscribe-card-container" className='card-height'>
        {/* eslint-disable-next-line react/jsx-no-bind*/}
        <div className='subscribe-card' onClick={() => onChange(plan)}>
          <div>
            <div>
              <div className='subscription-header' style={{background:plan.color}}>
                <b>{plan.planName}</b>
              </div>
              <div className='subscribe-amount'>
                <div className='subscription-pricing'>
                  {plan.offerPrice ? <span className='subscribe-amount-newcount'>  ${plan.offerPrice}  / month</span> : <span>Free</span> }
                </div>
              </div>
              {/* {getDiscountPercentage(plan.originalPrice, plan.offerPrice) > 0 && <span className='subscribe-discount'>
                {content.save} {getDiscountPercentage(plan.originalPrice, plan.offerPrice)}%
                                                                                 </span>} */}
            </div>
            <br />
            {plan.subscriptionType == SubsriptionType.Free ? getFreePlan() : plan.subscriptionType == SubsriptionType.Gold ? getGoldPlan() : getPlatinumPlan()}
          </div>
          {subscription.type === plan.subscriptionType && subscription.isMonthly === plan.isMonthly  ?
            <div className='form-row claim-offer-btn'>
              <Button id='subscribe-button' disabled className="button blue btn btn-primary" onClick={() => {doPayment(plan.id);}}>Active</Button>
            </div>:
            plan.subscriptionType != SubsriptionType.Free ?
              <div className='form-row claim-offer-btn'>
                <Button id='subscribe-button' disabled={isDisableSubmit} className="button blue btn btn-primary" onClick={() => {doPayment(plan.id);}}>Get Plan</Button>
              </div> : null}
        </div>
      </Card>));
  }


  return (
    <Page
      id="preferences-selection-component" data-testid="changeEmail"
      centerElement={local.billing.pageTitle}
      backPath={{ to: routePaths.preferences }}>
      <div className='plan-container row'>
        <div className='plan-bold-content col-12'>Current Plan : {subscription.name}</div>
        {subscription.type !== SubsriptionType.Free && <div className='plan-bold-content col-12'>Expires      : {moment(subscription.expiry).format('MMMM Do YYYY')}</div>}
        <br />
        <div>
          <ul className="tab-list" style={{float:'right'}}>
            <li
              className={isMonthly ? 'tab-list-item tab-list-active' : 'tab-list-item'}
              onClick={() => {setTab(true);}}>
              Monthly
            </li>
            <li
              className={!isMonthly ? 'tab-list-item tab-list-active' : 'tab-list-item'}
              onClick={() => {setTab(false);}}>
              Yearly
            </li>
          </ul>
        </div>
        {!isLoading && renderPlans()}
      </div>
    </Page>
  );
}

function getPlatinumPlan() {
  return (<div style={{fontSize:'13px', paddingTop:'5px', height:'250px'}}>
    <ul style={{listStyle: 'square'}}>
      <li>Everything in Gold plan, plus:</li>
      <li>Access Family Health Data</li>
      <li>Complementary Spouse & Children Accounts</li>
      <li>Elderly Care Monitoring</li>
      <li>Calcium AI Coach</li>
    </ul>
          </div>);
}

function getGoldPlan() {
  return (<div style={{ fontSize: '13px', paddingTop:'5px', height:'250px' }}>
    <ul style={{listStyle: 'square'}}>
      <li>Everything in Basic plan, plus:</li>
      <li>Access to Electronic Health Records (EHR)</li>
      <li>Integrate with Medical Device & Health App Data</li>
      <li>Lab Results</li>
      <li>Share Your Data & Records with Healthcare Provider</li>
      <li>Advanced Guided Pathways for Wellness, Fitness, Weight Loss and Chronic Conditions</li>
    </ul>
          </div>);
}

function getFreePlan() {
  return (<div style={{ fontSize: '13px', paddingTop:'5px', height:'250px' }}>
    <ul style={{listStyle: 'square'}}>
      <li>Health Journal</li>
      <li>Medication Manager</li>
      <li>Integrate Apple Health & Google Fit Data</li>
      <li>Basic Guided Pathways for Wellness, Fitness, Weight Loss, and Chronic Conditions</li>
      <li>Wellness Challenges</li>
    </ul>
          </div>);
}

function mapStateToProps(state) {
  return {
    initialValues: {
      initialEmail: state.preferences.newEmail
    }
  };
}

export default connect(mapStateToProps, null)(ChangeEmail);
