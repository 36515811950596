import { deviceManagerRoot as apiRoot} from 'app/config';
import { httpGet, httpPost, buildUrl, parseSuccess, parseError} from './api';

export const connectTerraDevice = (state, profileId, providerUserId) =>{
  var url = buildUrl(apiRoot, '/api/Terra/connect-device', { profileId, providerUserId });
  return httpPost(url, {}, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getTerraConnectionUrl= (profileId, deviceSourceName, saveRequest, state) => {
  var url = buildUrl(apiRoot, '/api/Terra/connection-url', { profileId, deviceSourceName });
  return httpPost(url, saveRequest, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getTerraDevices = (state, profileId) =>{
  var url = buildUrl(apiRoot, '/api/Terra/get-connected-devices', { profileId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const disconnectTerraDevice = (profileId, providerUserId, state) => {
  var url = buildUrl(apiRoot, '/api/Terra/disconnect-device', { profileId:profileId, providerUserId:providerUserId });
  return httpPost(url, {}, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getObservationDevices = (state, profileId, programIds) => {
  var url = buildUrl(apiRoot, '/api/ProfileObservationConnection/get-create', { profileId });
  return httpPost(url, programIds, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const saveObservationDevices = (state, profileId, body) => {
  var url = buildUrl(apiRoot, '/api/ProfileObservationConnection', { profileId });
  return httpPost(url, body, state)
    .catch(parseError);
};

export const postDeviceSurveyNotes = (state, body) =>
  httpPost(`${apiRoot}/api/ConnectionNote`, body, state);

export const getActiveDevices = (state, profileId) =>{
  var url = buildUrl(apiRoot, '/api/Device', { profileId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};
